module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Yes Mama","short_name":"Yes Mama","lang":"ja","description":"ピラティスと北海道の野菜で、お客様が良かった。感動した。と思ってもらえるように『シンプル』にわかりやすく『ピュア』に感じてもらえるように厳選し『ベスト』の追求を想いギュっと詰め込んだこだわりのサービスをご提供していきます。北海道札幌市のYes Mama。","background_color":"#FFFFFF","theme_color":"#FFFFFF","display":"standalone","scope":"/","start_url":"/","icon":"static/favicon.png","icons":[{"src":"static/android-chrome-192x192.png","sizes":"192x192","type":"image/png"},{"src":"static/maskable-icon-192x192.png","sizes":"192x192","type":"image/png","purpose":"maskable"},{"src":"static/android-chrome-512x512.png","sizes":"512x512","type":"image/png"},{"src":"static/maskable-icon-512x512.png","sizes":"512x512","type":"image/png","purpose":"maskable"}],"legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"b2cb2faf95f3595350513ad4ca9cd57f"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
